var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-box"},[_c('div',{staticClass:"sign-wrapper"},[_vm._m(0),_c('h1',[_vm._v("Sign In")]),_c('ValidationObserver',{ref:"signIn",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"Email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Email","disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('div',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{name: 'signup'}}},[_vm._v("Редактировать почту")])],1)],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Пароль","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"type":"password","state":errors[0] ? false : (valid ? true : null),"placeholder":"Пароль"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"type":"submit","variant":"primary submit"}},[_vm._v("Sign In")])],1)],1)}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("./images/logo.svg"),"alt":""}})])}]

export { render, staticRenderFns }