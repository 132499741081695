<template>
    <div class="sign-box">
        <div class="sign-wrapper">
            <div class="logo">
                <img src="./images/logo.svg" alt="">
            </div>
            <h1>Sign In</h1>
            <ValidationObserver ref="signIn">
                <b-form slot-scope="{ validate }" @submit.prevent="handleSubmit">
                    <ValidationProvider rules="required|email" name="Email" tag="div">
                        <div class="form-group" slot-scope="{ valid, errors }">
                            <b-form-input
                                v-model="email"
                                :state="errors[0] ? false : (valid ? true : null)"
                                placeholder="Email"
                                disabled
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                            <div class="text-right">
                                <router-link :to="{name: 'signup'}">Редактировать почту</router-link>
                            </div>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider rules="required" name="Пароль" tag="div">
                        <div class="form-group" slot-scope="{ valid, errors }">
                            <b-form-input
                                type="password"
                                v-model="password"
                                :state="errors[0] ? false : (valid ? true : null)"
                                placeholder="Пароль">
                            </b-form-input>
                            <b-form-invalid-feedback>
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <div class="text-center">
                        <b-button type="submit" variant="primary submit">Sign In</b-button>
                    </div>
                </b-form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'

    export default {
        name: "signin",
        computed: {
            ...mapGetters([
                'email'
            ]),
        },
        methods: {
            ...mapActions([
                'signIn',
            ]),
            async handleSubmit() {
                const isValid = await this.$refs.signIn.validate();
                if(isValid){
                    const data = {
                        email: this.email,
                        password: this.password
                    };
                    const roles = ['ADMIN', 'ROOT'];
                    this.signIn(data).then(userApi => {
                        const role = userApi.user.role;
                        if(roles.includes(role)){
                            this.$router.push({name: 'forums', replace: true})
                        } else {
                            this.$store.dispatch('signOut');
                            this.$swal({
                                icon: 'warning',
                                text: 'Вы не являетесь администратором!!!',
                                confirmButtonText: 'Ok',
                            });
                        }
                    }).catch(error => {
                        errorsAlertApi(error);
                    })
                }
            },
        },
        data(){
            return {
                password: ''
            }
        }
    }
</script>

<style scoped>

</style>